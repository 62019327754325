import React from 'react';
import { BsGithub } from 'react-icons/bs';
import {AiFillLinkedin} from 'react-icons/ai';


const handleClick = () => {
  window.open('https://www.linkedin.com/in/adamparkerlarue')
}
const handleclick2 = () => {
  window.open('https://github.com/adamlarue19')
}

const SocialMedia = () => (
 
  <div className="app__social">
    <div>
    <button onClick={handleClick}> <AiFillLinkedin />  </button>   
      
    </div>
    <div>
     <button onClick={handleclick2}> <BsGithub /> </button>
    </div>
  </div>
);

export default SocialMedia;
